import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../../layouts/Default';
import BlogRoll from '../../components/BlogRoll/BlogRoll';
import Section from '../../components/Section';
import BlogSectionTabs from '../../components/BlogSectionTabs/BlogSectionTabs';

export default function BlogIndexPage({
  data: {
    site: {
      siteMetadata: { title },
    },
  },
}) {
  return (
    <Layout>
      <Helmet title={`Blog | ${title}`} />
      <Section
        sx={(theme) => ({
          paddingTop: theme.spacing(6),
          paddingBottom: theme.spacing(6),
        })}
      >
        <BlogSectionTabs selectedTab="blog" />
        <br />
        <BlogRoll />
      </Section>
    </Layout>
  );
}

BlogIndexPage.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.object,
  }),
};

export const blogPageQuery = graphql`
  query BlogQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
